<template>
  <div class="row justify-content-center" style="min-height: calc(100vh - 180px);">
    <div class="card col-md-10 p-0">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="closeButton"
        />
      </div>
      <form @submit.prevent="saveOPDBilling">
        <div class="px-3 py-1">
          <div class="row">
            <div class="col-md-6">
              <label class="form-label">Consultant</label>
              <v-select placeholder="Select consultant"
                  v-model="formData.service_resource_id"
                  :options="serviceResources"
                  :reduce="name => name.id"
                  label="name"
                  @option:selected="onSelectServiceResource"
              />
            </div>
            <div class="col-md-3">
              <label class="form-label">Service Serial No</label>
              <vField
                  name="service_serial_no"
                  type="text"
                  class="form-control"
                  v-model="formData.service_serial_no"
                  readonly=""
              />
            </div>
          </div>

          <div class="row mb-2 mt-2 d-flex align-items-center">
            <div class="col-md-6">
              <input
                  @change="onSelectServiceResource"
                  v-model="formData.date"
                  name="datetime"
                  type="datetime-local"
                  class="form-control"
              />
            </div>
            <div class="col-md-6">
              <input class="form-check-input mr-3" type="checkbox">
              <label class="form-check-label" for="sendSms">Send SMS</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <AsyncSelect
                placeholder="Patient Name, ID, Mobile No"
                v-model="selectedPatient"
                :api-service="fetchContactProfiles"
                :additional-query="additionalQuery"
                :format-label="formatPatientLabel"
                :additional-option="additionalOption"
              />
            </div>

            <div class="col-md-2">
              <button
                  type="button"
                  class="btn btn-primary new-patient-action-btn"
                  @click="openPatientAddModal"
              >
                New Patient
              </button>
            </div>

            <div class="col-md-4">
                <input
                    v-model="patientSerialNo"
                    type="number"
                    placeholder="Patient serial no"
                    class="form-control"
                />
            </div>

            <div class="col-md-1">
              <button 
                type="button"
                class="btn btn-primary"
                @click="searchPatientById"
              >
                  Go
              </button>
            </div>

          </div>

          <div class="row py-1 justify-content-between">
            <div class="col-md-6">
              <div class="mb-1">
                <label class="form-label" for="fullName">Full Name</label>
                <vField
                    name="full_name"
                    type="text"
                    class="form-control"
                    v-model="patient.full_name"
                    readonly=""
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="patientId">Patient ID</label>
                <vField
                    name="patient_id"
                    type="text"
                    class="form-control"
                    v-model="patient.serial_no"
                    readonly=""
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-1">
                <label class="form-label" for="mobileNo">Mobile No</label>
                <vField
                    name="mobile"
                    type="text"
                    class="form-control"
                    v-model="patient.mobile_no"
                    readonly=""
                />
              </div>
              <div class="d-flex gap-1">
                <div class="mb-1 col-md-5">
                  <label class="form-label" for="age">Age</label>
                  <vField
                      name="birthday"
                      type="text"
                      class="form-control"
                      v-model="patient.age"
                      readonly=""
                  />
                </div>
                <div class="mb-1 col-6">
                  <label class="form-label" for="gender">Gender</label>
                  <vField
                      name="gender"
                      type="text"
                      class="capitalize form-control"
                      v-model="patient.gender"
                      readonly=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="background: #DBE7F2" class="p-2">
          <TitleButton
              btnTitle="Add New"
              :showBtn="false"
              :showSettingBtn="false"
              :showAddNew="false"
              :title="'Pay Now'"
          />
        </div>

        <div class="px-3 py-1">
          <div class="row py-1 justify-content-between">
            <div class="col-md-6">
              <div class="mb-1">
                <label class="form-label" for="visitType">Visit Type</label>
                <v-select
                    placeholder="Select Visit Type"
                    v-model="formData.service_specification"
                    label="name"
                    :options="specificationArr"
                    :reduce="name => name.id"
                    @option:selected="onSelectVisitType"
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="feesAmount">Fees Amount</label>
                <vField
                    v-model="account_details.amount"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="discount">Discount / Round up adjust</label>
                <vField
                    v-model="formData.adjustment_amount"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
              </div>
              <div class="mb-1" v-if="formData.adjustment_amount > 0">
                <label class="form-label" for="discount">Discount Acount Head</label>
                <v-select
                  placeholder="Select Discount Head"
                  v-model="formData.discount_head_id"
                  :options="salesAccountHeads"
                  label="name"
                  :reduce="name => name.id"
                />
              </div>

              <div class="mb-1">
                <label class="form-label" for="netPayable">Net Payable</label>
                <vField
                    v-model="netPayable"
                    readonly=""
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-1">
                <label class="form-label" for="paymentMode">Mode of Payment</label>
                <v-select
                    placeholder="Select Cash & Bank Account"
                    class="w-100"
                    v-model="formData.mop_account_head_id"
                    :options="cashAndBank"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="mopReference">MoP Reference</label>
                <vField
                    v-model="formData.receipt_reference"
                    name="mop_reference"
                    type="text"
                    class="form-control"
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="receiptAmount">Receive Amount</label>
                <vField
                    v-model="formData.payment"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
              </div>
              <div class="w-100">
                <button type="submit" class="btn btn-primary w-100" :disabled="loading">Save and Print</button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>

    <AddPatientModal
        v-if="$store.state.isModalOpenTwo"
        :doctors="doctors"
        @onCreateProfile="onCreateProfile"
    />

    <Loader v-if="loading"/>

  </div>

</template>

<script setup>
import {computed, inject, onMounted, reactive, ref, watch} from 'vue';
import TitleButton from '@/components/atom/TitleButton.vue';
import {useRoute, useRouter} from "vue-router";
import handleHospital from "@/services/modules/hospital";
import Loader from "@/components/atom/LoaderComponent.vue";
import handleInventory from "@/services/modules/inventory";
import handleReceipt from "@/services/modules/receipt";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import objToArray from "@/services/utils/object-to-array";
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import handlePurchase from "@/services/modules/purchase";
import handleVoucherNo from "@/services/modules/refNumber";
import AddPatientModal from '@/components/molecule/company/hospital/AddPatientModal'
import { useStore } from 'vuex';
import handleContact from '@/services/modules/contact'
import hospitalPdfPrinterHelper from '@/services/utils/hospitalPdfPrinterHelper';
import handleCompany from "@/services/modules/company";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

const { fetchContactProfiles } = handleContact()
const { generatePdf } = hospitalPdfPrinterHelper();
const { fetchCompanyInfo } = handleCompany();
const { getAccountHeadBySlag } = handlePurchase()
const { formatPatientLabel } = useAsyncDropdownHelper()
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const $route = useRoute();
const $router = useRouter();
const $store = useStore();

let headerTitle = ref('Create OPD Billing');
const loading = ref(false);
const invoiceRes = ref({})
const salesAccountHeads = ref([])
const company = ref({});
const patientSerialNo = ref(null);
const additionalQuery = {
  type: "patient"
}

const openPatientAddModal = () => {
   $store.state.isModalOpenTwo = true
}

const closeButton = () => {

  if($route.query.contactId){
    $router.push({
      name: "patient-register",
      params: $route.params,
      query: $route.query,
    });
    return;
  }

  let params = {
    companyId: $route.params.companyId,
    moduleId: $route.params.moduleId,
    menuId: $route.params.menuId,
    pageId: $route.params.pageId,
  }
  $router.push({
    name: 'opd-billing-list',
    params: params,
    query: $route.query
  })
}

const getCurrentDateTime = () => {
  const offset = new Date().getTimezoneOffset();
  const localDate = new Date(Date.now() - offset * 60000);
  return localDate.toISOString().slice(0, 16);
};

const formData = ref({
  account_head_id: null,
  account_payable_head_id: null,
  vat_payable_account_head_id: null,
  doctor_contact_profile_id: null,
  bill_number: null,
  service_specification: null,
  service_resource_id: null,
  resource_description_id: null,
  contact_profile_id: null,
  adjustment_amount: 0,
  discount_head_id: null,
  date: getCurrentDateTime(),
  service_end_date_time: null,
  service_serial_no: '',
  has_item_detail: false,
  account_details: [],
  cost_of_sales_price: '',
  cost_of_sales_account_head_id: null,
  description: 'OPD service bill',
  sale_type: 'opd_invoice',
  receipt_type: 'opd_invoice',
  status: 'active',

  payment_bill_number: null,
  mop_account_head_id: null,
  receipt_reference: '',
  payment: 0,
})

const account_details = ref({
  account_head_id: null,
  product_id: null,
  amount: '',
  vat_amount: 0,
  description: 'OPD service bill',
  taxable_amount: null,
  tax_rate: null,
  discount_amount: null,
  discount_percent: 0,
  total_amount: 0,
  service_resource_price: 0,
  service_resource_id: null,
  service_resource_description_id: null,
})

const cashAndBank = ref([])
const accountReceivable = ref([])
const accountPayable = ref([])
const vatPayable = ref([])
const additionalOption = ref([])
const serviceResources = ref([])
const patient = ref({});
const selectedPatient = ref(null);
const doctors = reactive([]);
const homeContent = ref({
  resource_type: {},
  resource_specifications: {}
})

const companyId = computed(() => $route.params.companyId);
const specificationArr = computed(() => objToArray(homeContent.value.resource_specifications))
const { fetchServiceSerialNo} = handleVoucherNo()
const {fetchCashAndBank} = handleReceipt();
const {fetchAccountReceivable, fetchVatPayable, fetchAccountPayable, fetchSalesBill} = handlePurchase()
const {fetchHome} = handleInventory();
const {storeOPDBilling} = handleHospitalBilling();
const {fetchPatientDetails, fetchServiceResourceList, dateOfBarthConvertToAge, fetchPatientDetailsBySerial} = handleHospital()

const onCreateProfile = async (profileInfo) => {
  selectedPatient.value = {
    id: profileInfo.id,
    name: profileInfo.full_name
  };
  additionalOption.value.push(selectedPatient.value)
  formData.value.contact_profile_id = profileInfo.id;
  await onSelectPatient(profileInfo);
}

const clearSelection = () => {
  console.log('clearSelection')
}

const onSelectServiceResource = async (selectedServiceResource) => {
  formData.value.doctor_contact_profile_id = selectedServiceResource.contact_profile_id
  let res = await fetchServiceSerialNo(`?company_id=${companyId.value}&voucher_type=service_sl_no&service_resource_id=${formData.value.service_resource_id}&date=${formData.value.date}`);
  formData.value.service_serial_no = res.data
}

const searchPatientById = async () => {

    if(!patientSerialNo.value) return;
    
    patient.value = {}
    loading.value = true;
    const companyQuery = `?company_id=${companyId.value}`

    await fetchPatientDetailsBySerial(companyQuery, patientSerialNo.value).then((res) => {

        if (!res.status) {
          return showError(res.message)
        }

        patient.value = res.data;
        selectedPatient.value = null;

        formData.value.contact_profile_id = patient.value.id;

        if (patient.value.birthday) {
          const {year, month, day} = dateOfBarthConvertToAge(patient.value.birthday)
          patient.value.age = `${year}Y, ${month}M, ${day}D`
        }

    })

    loading.value = false;
}

const onSelectPatient = async (profileInfo) => {

  patientSerialNo.value = null;

  const companyQuery = `?company_id=${companyId.value}`
  await fetchPatientDetails(companyQuery, profileInfo.id).then((res) => {
    if (!res.status) {
      return showError(res.message)
    }

    patient.value = res.data;

    if (patient.value.birthday) {
      const {year, month, day} = dateOfBarthConvertToAge(patient.value.birthday)
      patient.value.age = `${year}Y, ${month}M, ${day}D`
    }
  })
}

const onSelectVisitType = () => {
  account_details.value.amount = 0;
  formData.value.resource_description_id = account_details.value.account_head_id = account_details.value.account_head_id = formData.value.cost_of_sales_account_head_id = null
  formData.value.cost_of_sales_price = formData.value.service_end_date_time = '';

  const {service_resource_id, service_specification} = formData.value;
  if (!service_resource_id || !service_specification) {
    return
  }

  const findServiceResource = serviceResources.value.find((item) => item.id == service_resource_id)
  if (!findServiceResource) {
    return
  }

  const findServiceResourceDesc = findServiceResource.resource_descriptions.find((desc) => desc.service_specification == service_specification)
  if (!findServiceResourceDesc) {
    return
  }

  formData.value.resource_description_id = findServiceResourceDesc.id
  account_details.value.product_id = findServiceResourceDesc.product_id
  account_details.value.amount = findServiceResourceDesc.sales_price
  account_details.value.account_head_id = findServiceResourceDesc.sales_account_head_id
  account_details.value.service_resource_price = findServiceResourceDesc.cost_of_sales_price
  account_details.value.service_resource_id = formData.value.doctor_contact_profile_id
  account_details.value.service_resource_description_id = findServiceResourceDesc.id

  formData.value.cost_of_sales_price = findServiceResourceDesc.cost_of_sales_price
  formData.value.cost_of_sales_account_head_id = findServiceResourceDesc.cost_of_sales_account_head_id

  const duration = parseInt(findServiceResourceDesc.service_duration)
  const startTime = new Date(formData.value.date);
  const endTime = new Date(startTime.getTime() + duration * 60000);
  formData.value.service_end_date_time = new Date(endTime.getTime() - endTime.getTimezoneOffset() * 60000).toISOString().slice(0, 16);
}

const setInitialValue = (array, field) => {
  if(!array.length) return;
  formData.value[field] = array[0].id
}

// Create a computed property to calculate netPayable
const netPayable = computed(() => {
  // Parse the input values as numbers (assuming they are numeric input fields)
  const fees = parseFloat(account_details.value.amount);
  const vat = parseFloat(account_details.value.vat_amount);
  const discount = parseFloat(formData.value.adjustment_amount);

  // Calculate the netPayable amount
  const net = fees + (vat ? vat : 0) - (discount ? discount : 0);

  // Ensure the result is a non-negative number
  const netPayableAmount = isNaN(net) || net < 0 ? 0 : net;
  formData.value.payment = netPayableAmount

  return netPayableAmount;
});

const saveOPDBilling = () => {
  loading.value = true

  const formattedData = formatData();

  storeOPDBilling(formattedData).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    showSuccess(res.message)
    goToPrint(res.data.sale_master_id, res.data);
    resetForm();
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const resetForm = () => {
  formData.value.doctor_contact_profile_id = null;
  formData.value.bill_number = null;
  formData.value.service_specification = null;
  formData.value.service_resource_id = null;
  formData.value.resource_description_id = null;
  formData.value.contact_profile_id = null;
  formData.value.date = getCurrentDateTime();
  formData.value.service_end_date_time = null;
  formData.value.service_serial_no = '';
  formData.value.has_item_detail = false;
  formData.value.account_details = [];
  formData.value.cost_of_sales_price = '';
  formData.value.cost_of_sales_account_head_id = null;
  formData.value.description = 'OPD service bill';
  formData.value.status = 'active';
  formData.value.payment_status = 'paid';

  formData.value.payment_bill_number = null;
  formData.value.receipt_reference = '';
  formData.value.paid_amount = 0;

  account_details.value.amount = '';
  account_details.value.vat_amount = 0;
  account_details.value.description = 'OPD service bill';
  account_details.value.taxable_amount = null;
  account_details.value.tax_rate = null;
  account_details.value.discount_amount = null;
  account_details.value.discount_percent = 0;
  account_details.value.total_amount = 0;

  patient.value = {};
  selectedPatient.value = null;
}

const goToPrint = (id, data) => {
  const companyQuery = `?company_id=${companyId.value}`;

  fetchSalesBill(id, companyQuery).then( (res) => {
    if( res ){
      invoiceRes.value = res.data;
      invoiceRes.value = {...data, ...invoiceRes.value}
      invoiceRes.value.consultant = invoiceRes.value.service_resource.name;
    }
  }).then( () => {
      generatePdf(company.value, invoiceRes.value, 'OPD BILL', true, true, true)
  }).catch( (err) => {
      console.log(err);
  })
}

const formatData = () => {

  const formattedAccountDetails = structuredClone(account_details.value);
  formattedAccountDetails.total_amount = netPayable.value
  formattedAccountDetails.vat_amount = formattedAccountDetails.vat_amount ? formattedAccountDetails.vat_amount : 0;
  formattedAccountDetails.discount_amount = formattedAccountDetails.discount_amount ? formattedAccountDetails.discount_amount : 0;

  const formattedData = structuredClone(formData.value);
  formattedData.company_id = companyId.value
  formattedData.account_details = [formattedAccountDetails];
  formattedData.vat_amount = formattedData.vat_amount ? formattedData.vat_amount : 0;
  formattedData.discount_amount = formattedData.discount_amount ? formattedData.discount_amount : 0;
  return formattedData;
}

watch(selectedPatient, (newValue) => {
  if (newValue) {
    formData.value.contact_profile_id = newValue.id;
    onSelectPatient(newValue)
  }
})

onMounted(async () => {
  loading.value = true
  const companyQuery = `?company_id=${companyId.value}`

  const homeRes = fetchHome();
  const serviceResourceRes = fetchServiceResourceList(companyQuery);
  const cashAndBankRes = fetchCashAndBank(companyId.value);
  const receivableRes = fetchAccountReceivable(companyQuery)
  const payableRes = fetchAccountPayable(companyQuery)
  const vatPayableRes = fetchVatPayable(companyQuery)
  const salesAccountHeadsRes = getAccountHeadBySlag("sales_accounts", companyQuery)
  await Promise.all([
    homeRes.then(({data}) => {
      if (data) {
        const {resource_type, resource_specifications} = data;
        homeContent.value.resource_type = resource_type;
        homeContent.value.resource_specifications = resource_specifications;
      }
    }),
    fetchContactProfiles(companyQuery + `&type=doctor`).then((res) => {
        if(res.status) doctors.push(...res.data)
    }),
    serviceResourceRes.then(({data}) => {
      if (data) serviceResources.value = data
    }),
    cashAndBankRes.then(({data}) => {
      if (data) cashAndBank.value = data
    }),
    receivableRes.then(({data}) => {
      if (data) accountReceivable.value = data
      if (data.length < 1) {
        return formData.value.account_head_id = null;
      }

      formData.value.account_head_id = data[0].id
    }),
    payableRes.then(({data}) => {
      if (data) accountPayable.value = data
      if (data.length < 1) {
        return formData.value.account_head_id = null;
      }

      formData.value.account_payable_head_id = data[0].id
    }),
    vatPayableRes.then(({data}) => {
      if (data) vatPayable.value = data
      if (data.length < 1) {
        return formData.value.account_head_id = null;
      }

      formData.value.vat_payable_account_head_id = data[0].id
    }),
    fetchCompanyInfo(companyId.value).then((res) => {
      company.value = res.data
    }),
    salesAccountHeadsRes.then(res => {
      if(res.data) salesAccountHeads.value = res.data
    })
  ]).then(() => {

    setInitialValue(cashAndBank.value, 'mop_account_head_id');

    loading.value = false;
  }).catch((err) => {
    loading.value = false
  });
})
</script>

<style scoped>
.new-patient-action-btn {
  padding: 0.786rem 1rem !important;
}

.mr-3 {
  margin-right: 3px;
}

.mbt-20 {
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
